import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { FeeTypes } from '../../../data/Constants'
import { Translations } from '../../../data/Translations'
import { ICalculatorData } from '../../../prismicTypes'
import IconLeaf from '../../../public/svg/IconLeaf'
import { selectDeposit, selectMonths, selectPrice } from '../../../store/loan'
import Typography from '../../../styles/typography'
import {
  getPaymentSchedule,
  getAnnualPercentageRate,
  getMonthlyPayment,
  getLoanFee,
  getCostByType,
  getLoanPaymentSumValues,
  getInterestRate,
  getAPRWithStartingPoint,
} from '../../../utils/utils'
import FormattedNumber from '../../common/FormattedNumber'

interface IProps {
  calculatorData: ICalculatorData
  baseLoanData: ICalculatorData
}

const HeaderInfo: React.FC<IProps> = ({ calculatorData, baseLoanData }) => {
  const { breakdown } = Translations.is

  const price = useSelector(selectPrice)
  const deposit = useSelector(selectDeposit)
  const months = useSelector(selectMonths)

  const baseLoan = price - deposit
  const loanCost = getLoanFee(months, baseLoan, calculatorData)
  // getCostByType- type 6 = Þinglýsingargjald
  const registrationFee = getCostByType(FeeTypes.RegistrationFee, calculatorData.loan_data.cost)
  const paymentSchedule = getPaymentSchedule(price, deposit, months, calculatorData)
  const sumValues = getLoanPaymentSumValues(paymentSchedule)
  const paymentFee = getCostByType(FeeTypes.PaymentFee, calculatorData.loan_data.cost) // Greiðslugjald
  const monthlyPayment =
    getMonthlyPayment(
      baseLoan + loanCost + registrationFee,
      price,
      deposit,
      months,
      calculatorData.loan_data.interest
    ) + paymentFee

  const interestRate = getInterestRate(price, deposit, calculatorData.loan_data.interest)
  const aprOld = getAnnualPercentageRate(baseLoan, 0, monthlyPayment, months, 0)
  const aprNew = getAPRWithStartingPoint(paymentSchedule, baseLoan, aprOld, months)
  let apr = 0
  if (aprNew === 0) {
    apr = aprOld
  } else {
    apr = aprNew
  }
  // BaseLoanCalculations
  let isDiscountOnInterestRates = false
  let isDiscountOnLoanFee = false
  let baseLoanCost = 0
  let baseInterestRate = 0
  if (
    calculatorData.loan_data.loanType != baseLoanData.loan_data.loanType ||
    calculatorData.loan_data.loanSubType != baseLoanData.loan_data.loanSubType
  ) {
    baseLoanCost = getLoanFee(months, baseLoan, baseLoanData)
    baseInterestRate = getInterestRate(price, deposit, baseLoanData.loan_data.interest)
    if (baseLoanCost > loanCost && baseLoanCost > 0) {
      isDiscountOnLoanFee = true
    }
    if (baseInterestRate > interestRate && baseInterestRate > 0) {
      isDiscountOnInterestRates = true
    }
  }
  return (
    <>
      <SCHeaderRow>
        <span>{breakdown.paidAmount}</span>
        <FormattedNumber value={baseLoan} displayType="text" />
      </SCHeaderRow>
      <SCHeaderRow>
        {isDiscountOnLoanFee ? (
          <SCDiscountItems>
            <SCDiscountItem>{breakdown.loanCost}</SCDiscountItem>
            <SCDiscountStrikeThrough>
              <FormattedNumber value={baseLoanCost} displayType="text" />
            </SCDiscountStrikeThrough>
            <SCDiscountLeafText>
              <IconLeaf />
            </SCDiscountLeafText>
            <SCDiscountItem>
              <FormattedNumber value={loanCost} displayType="text" allowNegative={true} />
            </SCDiscountItem>
          </SCDiscountItems>
        ) : (
          <>
            <span>{breakdown.loanCost}</span>
            <FormattedNumber value={loanCost} displayType="text" />
          </>
        )}
      </SCHeaderRow>
      <SCHeaderRow>
        <span>{breakdown.registrationFee}</span>
        <FormattedNumber value={registrationFee} displayType="text" allowNegative={true} />
      </SCHeaderRow>
      <SCHeaderRow>
        {isDiscountOnInterestRates ? (
          <SCDiscountItems>
            <SCDiscountItem>{breakdown.interest}</SCDiscountItem>
            <SCDiscountStrikeThrough>
              <FormattedNumber value={baseInterestRate} displayType="text" decimalScale={2} suffix="%" />
            </SCDiscountStrikeThrough>
            <SCDiscountLeafText>
              <IconLeaf />
              <FormattedNumber
                value={interestRate - baseInterestRate}
                displayType="text"
                decimalScale={2}
                suffix="%"
                allowNegative={true}
              />
            </SCDiscountLeafText>
            <SCDiscountItem>
              <FormattedNumber value={interestRate} displayType="text" decimalScale={2} suffix="%" />
            </SCDiscountItem>
          </SCDiscountItems>
        ) : (
          <>
            <span>{breakdown.interest}</span>
            <FormattedNumber value={interestRate} displayType="text" decimalScale={2} suffix="%" />
          </>
        )}
      </SCHeaderRow>
      <SCHeaderRow>
        <span>{breakdown.repaymentMethod}</span>
        <span>{breakdown.equalPayments}</span>
      </SCHeaderRow>
      <SCHeaderRow>
        <span>{breakdown.annualPercentageRate}</span>
        <FormattedNumber value={apr} displayType="text" decimalScale={2} suffix="%" />
      </SCHeaderRow>
      <SCDivider />
      <SCHeaderRow>
        <SCTotalPaidText>{breakdown.totalPaid}</SCTotalPaidText>
        <SCTotalPaid value={sumValues.totalPaid} displayType="text" extraStyle={SCBold} />
      </SCHeaderRow>
    </>
  )
}

export default HeaderInfo

const SCHeaderRow = styled.div`
  ${({ theme }) => css`
    ${Typography.calculatorTableRow}
    display: flex;
    justify-content: space-between;
    color: ${theme.colors.blue400};
    line-height: 2.5rem;
  `}
`
const SCDiscountItems = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
    @media screen and (min-width: ${breakpoints.tabletMid}) {
      flex-wrap: nowrap;
      order: 1;
    }
  `}
`
const SCDiscountLeafText = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    width: 40%;
    margin-left: 2rem;
    span {
      padding-left: 0.25rem;
    }
    @media screen and (min-width: ${breakpoints.tabletMid}) {
      min-width: 20%;
      order: 2;
    }
  `}
`
const SCDiscountItem = styled.div`
  ${({ theme: { breakpoints } }) => css`
    display: flex;
    width: 50%;
    span {
      padding-left: 1rem;
      margin-left: auto;
    }
    @media screen and (min-width: ${breakpoints.tabletMid}) {
      min-width: 15%;
      width: 25%;
      :last-child {
        order: 4;
      }
    }
  `}
`
const SCDiscountStrikeThrough = styled.div`
  ${({ theme: { breakpoints } }) => css`
    text-decoration: line-through;
    min-width: 50%;
    text-align: end;
    color: red;
    @media screen and (min-width: ${breakpoints.tabletMid}) {
      width: 20%;
      min-width: 20%;
      display: flex;
      order: 3;
      justify-content: flex-end;
    }
  `}
`
const SCDivider = styled.div`
  ${({ theme }) => css`
    border-bottom: 0.0625rem solid ${theme.colors.blue400};
    width: 100%;
    margin: 1rem 0;
  `}
`

const SCTotalPaid = styled(FormattedNumber)`
  font-size: 1.125rem;
  font-weight: 900;
  ${({ theme }) => css`
    color: ${theme.colors.blue400};
  `}
`
const SCTotalPaidText = styled.span`
  ${({ theme }) => css`
    @media screen and (max-width: ${theme.breakpoints.tabletMin}) {
      font-size: 1rem;
      line-height: 2.5rem;
      letter-spacing: 0.01em;
    }
  `}
`
const SCBold = css`
  ${({ theme: { colors, breakpoints } }) => css`
    font-weight: 900;
    font-size: 1.125rem;
    color: ${colors.blue400};
    @media screen and (max-width: ${breakpoints.tabletMin}) {
      line-height: 2.5rem;
      letter-spacing: 0.01em;
    }
    @media screen and (max-width: ${breakpoints.mobileMax}) {
      letter-spacing: 0.01em;
    }
  `}
`
